/**
 * @note: temp fix for site status widget bg color as a module
 */
import { LitElement } from 'lit';

export default async function setToDark() {
  const rhSiteStatus: HTMLElement | null = document.querySelector('rh-site-status');

  await customElements.whenDefined('rh-site-status');
  await (rhSiteStatus as LitElement).updateComplete;
  rhSiteStatus?.shadowRoot?.querySelector('#container')?.classList.add('on', 'dark');
}